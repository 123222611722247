
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import UserForm from "./routes/UserForm/UserForm";
import Welcome from "./routes/Welcome/Welcome";
import Login from "./routes/Login/Login"; 
import DataView from "./routes/DataView/DataView"
import ThankYouPage from "./routes/ThankYouPage/ThankYouPage"; // Create this component for the thank you page


function App() {
  return (
    <Router>
      <div className="App">
        
        <Routes>
        <Route path="/" element={<Welcome />} />
          <Route path="/register" element={<UserForm />} />
          <Route path="/admin" element={<Login />} />
          <Route path="/dataview" element={<DataView />} />
          <Route path="/thankyou" element={<ThankYouPage />} />

      
        </Routes>
      </div>
    </Router>
  );
}

export default App;
