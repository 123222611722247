import React from "react";
import "./ThankYouPage.css";
import welcomeImage from "./1.png"; // Adjust the path to your image
import newImage from "./2.png"; // Adjust the path to your new image

const ThankYouPage = () => {
  return (
    <div className="thankyou-overlay">
      <div className="thankyou-modal">
        <div className="thankyou-container">
          <div className="thankyou-header">
            <img
              src={welcomeImage}
              alt="littmann_logo"
              className="welcome-image"
            />
            <img src={newImage} alt="sunshine_logo" className="new-image" />
          </div>
          <h1>Thank You!</h1>
          <p>
            Your details have been saved successfully, and the Customer ID has
            been sent to the entered email!
          </p>
          {/* <a href="/">Go back to home</a> */}
          {/* <button onClick={() => window.location.href = '/'}>Go back to home</button> */}
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
