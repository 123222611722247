import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import emailjs from "emailjs-com";
import { useNavigate } from "react-router-dom";
import leftImage from "./1.png";
import rightImage from "./2.png";
import { Loader2, AlertCircle } from "lucide-react";

const firebaseConfig = {
  apiKey: "AIzaSyBDrcE1MF2CTwEVLG7gu6YOX7ZPvJjZxDc",
  authDomain: "sunshine-85b93.firebaseapp.com",
  projectId: "sunshine-85b93",
  storageBucket: "sunshine-85b93",
  messagingSenderId: "842986701588",
  appId: "1:842986701588:web:c4d8071c2ff699a9007801",
  measurementId: "G-9P5TYYNJT2",
};

firebase.initializeApp(firebaseConfig);

const UserForm = () => {
  const [formData, setFormData] = useState({
    FullName: "",
    Email: "",
    ContactNo: "",
    Address: "",
    DealerName: "",
    InvoiceNumber: "",
    LittmannModel: "",
    Color: "",
    SerialNumber: "",
    selectedDate: null,
    honeypot: "",
  });

  const [emailExists, setEmailExists] = useState(false);
  const [serialExists, setSerialExists] = useState(false);
  const [userOrder, setUserOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    error: null,
  });
  const navigate = useNavigate();

  // Check form validity
  useEffect(() => {
    const validateForm = () => {
      // Check if all required fields are filled
      const allFieldsFilled = Object.entries(formData).every(([key, value]) => {
        if (key === "honeypot") return true; // Skip honeypot
        if (key === "selectedDate") return value !== null;
        return value !== "";
      });

      // Form is valid only if all fields are filled AND serial number doesn't exist
      const isValid = allFieldsFilled && !serialExists && !emailExists;
      setIsFormValid(isValid);
    };

    validateForm();
  }, [formData, serialExists, emailExists]);

  // Check serial number existence whenever serial number changes
  useEffect(() => {
    const timer = setTimeout(() => {
      if (formData.SerialNumber) {
        checkSerialExists();
      }
    }, 500); // Debounce serial check

    return () => clearTimeout(timer);
  }, [formData.SerialNumber]);

  const checkSerialExists = async () => {
    if (!formData.SerialNumber) return;

    const db = firebase.firestore();
    const snapshot = await db
      .collection("user")
      .where("SerialNumber", "==", formData.SerialNumber)
      .get();
    const exists = !snapshot.empty;
    setSerialExists(exists);

    if (exists) {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000);
    }
  };

  const getUserOrder = async () => {
    const db = firebase.firestore();
    const snapshot = await db
      .collection("user")
      .orderBy("UserOrder", "desc")
      .limit(1)
      .get();
    if (snapshot.empty) {
      return 1;
    } else {
      return snapshot.docs[0].data().UserOrder + 1;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      selectedDate: date,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Early return if form is invalid, honeypot is filled, or serial exists
    if (formData.honeypot || !isFormValid || serialExists || isSubmitting) {
      return;
    }
  
    setIsSubmitting(true);
  
    // Reset form data to clear the fields after 3 seconds
    setTimeout(() => {
      setFormData({
        FullName: "",
        Email: "",
        ContactNo: "",
        Address: "",
        DealerName: "",
        InvoiceNumber: "",
        LittmannModel: "",
        Color: "",
        SerialNumber: "",
        selectedDate: null,
        honeypot: "",
      });
    }, 3000); // 3-second delay before clearing fields
  
    const db = firebase.firestore();
    try {
      // Show loading state immediately
      setSubmitStatus({ loading: true, error: null });
  
      // Get user order
      const order = await getUserOrder();
      setUserOrder(order);
  
      // Create new document reference
      const docRef = await db.collection("user").doc();
  
      // Prepare document data
      const documentData = {
        ID: docRef.id,
        FullName: formData.FullName,
        Email: formData.Email,
        ContactNo: formData.ContactNo,
        Address: formData.Address,
        DealerName: formData.DealerName,
        InvoiceNumber: formData.InvoiceNumber,
        LittmannModel: formData.LittmannModel,
        Color: formData.Color,
        SerialNumber: formData.SerialNumber,
        Date: formData.selectedDate,
        UserOrder: order,
      };
  
      // Save to Firestore
      await docRef.set(documentData);
  
      // Prepare email data
      const emailData = {
        to_email: formData.Email,
        full_name: formData.FullName,
        contact_no: formData.ContactNo,
        address: formData.Address,
        dealer_name: formData.DealerName,
        invoice_no: formData.InvoiceNumber,
        littmann_model: formData.LittmannModel,
        color: formData.Color,
        serial_no: formData.SerialNumber,
        date: formData.selectedDate,
        generated_id: docRef.id,
      };
  
      // Send confirmation email
      await emailjs.send(
        "service_se2i8og",
        "template_cvph6jb",
        emailData,
        "jiD3gSghzufZrsvBk"
      );
  
      // Navigate to thank you page on success
      navigate("/thankyou");
    } catch (error) {
      console.error("Error during form submission: ", error);
      setSubmitStatus({
        loading: false,
        error: "An error occurred during submission. Please try again.",
      });
    } finally {
      setIsSubmitting(false);
      setSubmitStatus((prev) => ({ ...prev, loading: false }));
    }
  };
  
  
  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <img src={leftImage} alt="Left" className="h-16 w-auto" />
          <img src={rightImage} alt="Right" className="h-16 w-auto" />
        </div>

        {showAlert && (
          <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded-md flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            Serial Number already exists
          </div>
        )}

        <div className="bg-white shadow-xl rounded-lg p-8">
          <h2 className="text-2xl font-bold text-gray-900 text-center mb-8">
            Warranty Card Validation Form
          </h2>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Full Name
                </label>
                <input
                  type="text"
                  name="FullName"
                  value={formData.FullName}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  name="Email"
                  value={formData.Email}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                {emailExists && (
                  <p className="mt-1 text-sm text-red-600">
                    Email already exists
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Contact Number
                </label>
                <input
                  type="tel"
                  name="ContactNo"
                  value={formData.ContactNo}
                  onChange={handleChange}
                  required
                  pattern="[0-9]{10}"
                  title="Please enter a 10-digit contact number"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Address
                </label>
                <input
                  type="text"
                  name="Address"
                  value={formData.Address}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Dealer Name
                </label>
                <input
                  type="text"
                  name="DealerName"
                  value={formData.DealerName}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Invoice Number
                </label>
                <input
                  type="text"
                  name="InvoiceNumber"
                  value={formData.InvoiceNumber}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Littmann Model Purchased
                </label>
                <input
                  type="text"
                  name="LittmannModel"
                  value={formData.LittmannModel}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Color
                </label>
                <input
                  type="text"
                  name="Color"
                  value={formData.Color}
                  onChange={handleChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Serial Number
                </label>
                <input
                  type="text"
                  name="SerialNumber"
                  value={formData.SerialNumber}
                  onChange={handleChange}
                  required
                  className={`w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                    serialExists ? "border-red-500" : "border-gray-300"
                  }`}
                />
                {serialExists && (
                  <p className="mt-1 text-sm text-red-600">
                    Serial Number already exists
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Date
                </label>
                <DatePicker
                  selected={formData.selectedDate}
                  onChange={handleDateChange}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>

            <input
              type="text"
              name="honeypot"
              value={formData.honeypot}
              onChange={handleChange}
              className="hidden"
            />

            <div className="flex justify-center">
              <button
                type="submit"
                disabled={!isFormValid || isSubmitting || serialExists}
                className={`
    w-full sm:w-auto 
    px-6 py-3 
    font-medium 
    rounded-md 
    shadow-sm 
    transition-all 
    duration-200 
    ease-in-out
    transform
    focus:outline-none 
    focus:ring-2 
    focus:ring-offset-2 
    focus:ring-blue-500
    ${
      isFormValid && !isSubmitting && !serialExists
        ? "bg-blue-600 hover:bg-blue-700 text-white hover:scale-105 active:scale-95"
        : "bg-gray-200 text-gray-400 cursor-not-allowed opacity-60 hover:bg-gray-200 scale-95"
    }
    ${isSubmitting ? "animate-pulse" : ""}
  `}
              >
                <div className="flex items-center justify-center space-x-2">
                  {isSubmitting ? (
                    <>
                      <Loader2 className="animate-spin h-5 w-5" />
                      <span>Submitting...</span>
                    </>
                  ) : (
                    <span>Submit</span>
                  )}
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
