import React from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import welcomeImage from "./1.png";
import newImage from "./2.png";

Modal.setAppElement("#root");

const Welcome = () => {
  const navigate = useNavigate();

  const handleProceedClick = () => {
    navigate("/register");
  };

  return (
    <Modal
      isOpen={true}
      className="fixed inset-0 flex items-center justify-center p-4 outline-none"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
    >
      <div className="bg-white rounded-2xl shadow-2xl w-full max-w-2xl transform transition-all">
        <div className="p-8">
          {/* Logo Container */}
          <div className="flex items-center justify-center space-x-8 mb-8">
            <img
              src={welcomeImage}
              alt="littmann_logo"
              className="h-20 object-contain"
            />
            <img
              src={newImage}
              alt="sunshine_logo"
              className="h-20 object-contain"
            />
          </div>

          {/* Content */}
          <div className="text-center space-y-6">
            <h1 className="text-3xl font-bold text-gray-800 leading-tight">
              Welcome to Sunshine Health Care
              <br />
              Warranty Card Validation Portal
            </h1>
            
            <p className="text-gray-600">
              To proceed to the registration, click the button below.
            </p>

            <button
              onClick={handleProceedClick}
              className="mt-8 px-8 py-3 bg-blue-600 text-white font-semibold rounded-lg 
                       transform transition-all duration-200 
                       hover:bg-blue-700 hover:scale-105 active:scale-95
                       focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2
                       shadow-lg hover:shadow-xl"
            >
              ONLINE WARRANTY REGISTRATION
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Welcome;