import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [honeypot, setHoneypot] = useState(""); // Honeypot field state
  const navigate = useNavigate();

  const handleLogin = () => {
    const validUsername = "SunshineAdmin";
    const validPassword = "SunshineAdmin@123";

    // Check honeypot field
    if (honeypot) {
      setError("Bot submission detected!");
      return;
    }

    if (username === validUsername && password === validPassword) {
      console.log("Login successful");
      navigate("/dataview");
    } else {
      setError("Invalid username or password");
    }
  };

  // Function to handle key press events in the password field
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin(); // Trigger login action when Enter key is pressed
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <div className="form-group">
        <label>Username</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={handleKeyPress} // Call handleKeyPress function on key press
        />
      </div>
      {/* Honeypot field */}
      <div style={{ display: "none" }}>
        <label>Honeypot</label>
        <input
          type="text"
          value={honeypot}
          onChange={(e) => setHoneypot(e.target.value)}
        />
      </div>
      {error && <p className="error-message">{error}</p>}
      <button onClick={handleLogin}>Login</button>
    </div>
  );
};

export default Login;
